.panel-heading {
	background-color: #000000;
}

.navbar {
	background-color: #00111A;
}

.title-text {
	color: #FFFFFF;
}
